<template>
    <div class="create-dep">
        <div class="create-dep-box">
            <div class="create-dep-box-title">
                <h2>הוספת מחלקה</h2>
                <div class="exit-btn">
                    <i @click="$emit('close')" class="material-icons">close</i>
                </div>
            </div>
            <div class="create-dep-box-content">
                <label>
                    <h3>שם מחלקה:</h3>
                    <el-input v-model="data.name"  placeholder="שם המחלקה" />
                </label>
                <el-button v-if="!selected_dep_to_edit" @click="handle_submit" style="width: 100%; margin-top: 10px;" type="success">הוספה</el-button>
                <el-button v-else @click="handle_submit_edit" style="width: 100%; margin-top: 10px;" type="warning">עדכון</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import {departments,add_new_department_to_db,edit_exist_department} from '../store'
import {slide_pop_error,alert} from '../../../../../Methods/Msgs'
export default {
    props:['selected_dep_to_edit'],
    emits:['close'],
    setup (props,{emit}) {
        
        const data = ref({
            name:''
        })

        const validation = () => {
            if(!data.value.name) throw new Error('עליך להזין שם מחלקה!')
            const index = departments.value.findIndex(dep => dep.name == data.value.name)
            if(index != -1){
                throw new Error(`שם המחלקה ${data.value.name} כבר קיים!`)
            }
        }

        const handle_submit = async() => {
            try {
                validation()
                await add_new_department_to_db(data.value)
                alert('success','הצלחה',`המחלקה ${data.value.name} הוספה בהצלחה`)
                emit('close')
            } catch (err) {
                console.error(err.message);
                slide_pop_error(err.message)
            }finally{

            }
        }

        const handle_submit_edit = async() => {
            try {
                validation()
                await edit_exist_department(data.value)
                alert('success','הצלחה',`המחלקה ${data.value.name} עודכנה בהצלחה`)
                emit('close')
            } catch (err) {
                console.error(err.message);
                slide_pop_error(err.message)
            }finally{

            }
        }

        if(props.selected_dep_to_edit){
            console.log('check:',props.selected_dep_to_edit);
            data.value = props.selected_dep_to_edit
        }

        return {
            handle_submit,
            handle_submit_edit,
            data,
        }
    }
}
</script>

<style scoped>
    .create-dep{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        z-index: 1000;
        color: #333;
        padding: 0 5px;
    }
    .create-dep-box{
        margin-top: 20px;
        width: 100%;
        height: fit-content;
        max-width: 500px;
        background: #fff;
        border-radius: 10px;
    }
    .create-dep-box-title{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .create-dep-box-content{
        width: 100%;
        padding: 10px;
    }

    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: var(--danger);
        border-radius: 50%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }
</style>